import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "landing",
    component: () => import("@/views/Landing/landing-view.vue"),
  },
  {
    path: "/investee",
    name: "investee",
    redirect: "/investee/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/layout/Layout.vue"),
        children: [
          {
            path: "",
            name: "projects",
            component: () => import("@/views/projects/projects-list.vue"),
          },
          {
            path: ":submissionId",
            name: "project",
            component: () =>
              import("@/views/projects/project-detail-wrapper.vue"),
            children: [
              {
                path: "application",
                name: "application",
                component: () =>
                  import("@/views/investee-steps/step1/Step1.vue"),
              },
              {
                path: "onboarding",
                name: "onboarding",
                component: () =>
                  import("@/views/investee-steps/step2/Step2.vue"),
              },
              {
                path: "review",
                name: "review",
                component: () =>
                  import("@/views/investee-steps/step3/Step3.vue"),
              },
              {
                path: "bdr",
                name: "bdr",
                component: () =>
                  import("@/views/investee-steps/step4/Step4.vue"),
              },
              {
                path: "investment",
                name: "investment",
                redirect: {
                  name: "investee-investment_match",
                },
                component: () =>
                  import("@/views/investee-steps/step5/Step5.vue"),
                children: [
                  {
                    path: "match",
                    name: "investee-investment_match",
                    component: () =>
                      import(
                        "@/custom-components/match-list/inProgress-list.vue"
                      ),
                  },
                  {
                    path: "closed",
                    name: "investee-investment_closed",
                    component: () =>
                      import("@/custom-components/match-list/closed-list.vue"),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "auth",
        component: () => import("@/components/page-layouts/Auth.vue"),
        children: [
          {
            path: "sign-in",
            name: "investee-sign-in",
            component: () =>
              import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
          },
          {
            path: "sign-up",
            name: "investee-sign-up",
            component: () =>
              import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
          },
          {
            path: "password-reset",
            name: "investee-password-reset",
            component: () =>
              import(
                "@/views/crafted/authentication/basic-flow/PasswordReset.vue"
              ),
          },
          {
            path: "password-recovery",
            name: "investee-password-recovery",
            component: () =>
              import(
                "@/views/crafted/authentication/basic-flow/SetNewPassword.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/intermediary",
    name: "intermediary",
    redirect: "/intermediary/home/application",
    children: [
      {
        path: "home",
        name: "intermediary-home",
        component: () => import("@/layout/Layout.vue"),
        children: [
          {
            path: "application",
            name: "intermediary-application",
            component: () =>
              import("@/views/intermediary-steps/step1/Step1.vue"),
          },
          {
            path: "review",
            name: "intermediary-review",
            component: () =>
              import("@/views/intermediary-steps/step2/Step2.vue"),
          },
          {
            path: "onboarding",
            name: "intermediary-onboarding",
            component: () =>
              import("@/views/intermediary-steps/step3/Step3.vue"),
          },
          {
            path: "deal-support",
            name: "intermediary-deal-support",
            redirect: (to) => {
              return to.fullPath + "/list/possible";
            },
            component: () =>
              import("@/views/intermediary-steps/step4/Step4.vue"),
            children: [
              {
                path: "list/:listType",
                name: "deal-list",
                component: () =>
                  import(
                    "@/views/intermediary-steps/step4/views/deals-view.vue"
                  ),
              },
              {
                path: "detail/:id",
                name: "deal-detail",
                redirect: { name: "deal-detail-application" },
                component: () =>
                  import(
                    "@/views/intermediary-steps/step4/views/deal-detail.vue"
                  ),
                children: [
                  {
                    path: "application",
                    name: "deal-detail-application",
                    component: () =>
                      import(
                        "@/views/intermediary-steps/step4/views/components/application-form-investee.vue"
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "auth",
        component: () => import("@/components/page-layouts/Auth.vue"),
        children: [
          {
            path: "sign-in",
            name: "intermediary-sign-in",
            component: () =>
              import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
          },
          {
            path: "sign-up",
            name: "intermediary-sign-up",
            component: () =>
              import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
          },
          {
            path: "password-reset",
            name: "intermediary-password-reset",
            component: () =>
              import(
                "@/views/crafted/authentication/basic-flow/PasswordReset.vue"
              ),
          },
          {
            path: "password-recovery",
            name: "intermediary-password-recovery",
            component: () =>
              import(
                "@/views/crafted/authentication/basic-flow/SetNewPassword.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/investor",
    name: "investor",
    redirect: "/investor/home/application",
    children: [
      {
        path: "home",
        name: "investor-home",
        component: () => import("@/layout/Layout.vue"),
        children: [
          {
            path: "application",
            name: "investor-application",
            component: () => import("@/views/investor-steps/step1/Step1.vue"),
          },
          {
            path: "review",
            name: "investor-review",
            component: () => import("@/views/investor-steps/step2/Step2.vue"),
          },
          {
            path: "onboarding",
            name: "investor-onboarding",
            component: () => import("@/views/investor-steps/step3/Step3.vue"),
          },
          {
            path: "investment",
            name: "investor-investment",
            redirect: {
              name: "investor-investment_available",
            },
            component: () => import("@/views/investor-steps/step4/Step4.vue"),
            children: [
              {
                path: "available",
                name: "investor-investment_available",
                component: () =>
                  import(
                    "@/views/investor-steps/step4/views/available-list.vue"
                  ),
              },
              {
                path: "inProgress",
                name: "investor-investment_match",
                component: () =>
                  import("@/custom-components/match-list/inProgress-list.vue"),
              },
              {
                path: "closed",
                name: "investor-investment_closed",
                component: () =>
                  import("@/custom-components/match-list/closed-list.vue"),
              },
            ],
          },
          {
            path: "feedback/:id",
            name: "investor-match-feedbaack",
            component: () =>
              import("@/views/investor-steps/step4/views/feed-back.vue"),
          },
          {
            path: "materials/:id/:view",
            name: "investor-match-materials",
            component: () =>
              import("@/views/investor-steps/step4/views/materials-view.vue"),
          },
        ],
      },
      {
        path: "auth",
        component: () => import("@/components/page-layouts/Auth.vue"),
        children: [
          {
            path: "sign-in",
            name: "investor-sign-in",
            component: () =>
              import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
          },
          {
            path: "sign-up",
            name: "investor-sign-up",
            component: () =>
              import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
          },
          {
            path: "password-reset",
            name: "investor-password-reset",
            component: () =>
              import(
                "@/views/crafted/authentication/basic-flow/PasswordReset.vue"
              ),
          },
          {
            path: "password-recovery",
            name: "investor-password-recovery",
            component: () =>
              import(
                "@/views/crafted/authentication/basic-flow/SetNewPassword.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
