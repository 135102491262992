import axios from "@/core/services/axios";
import router from "@/router/clean";
import _ from "lodash";
import qs from "qs";

const SET_CURRENT_STEP_INVESTOR = "SET_CURRENT_STEP_INVESTOR";
const SET_SUBMISSION_STATUS_INVESTOR = "SET_SUBMISSION_STATUS_INVESTOR";
const SET_SUBMISSION_INFO_INVESTOR = "SET_SUBMISSION_INFO_INVESTOR";
const SET_AVAILABLE_LIST = "SET_AVAILABLE_LIST";
const SET_MATCH_LIST = "SET_MATCH_LIST";
const SET_FILTERS = "SET_FILTERS";

const defaultFilters = {
  sector: [],
  fintype: [],
};

const state = {
  step: 1,
  status: {
    step: 1,
    view: "",
  },
  submission: {},
  availableList: [],
  matchList: [],
  filters: { ...defaultFilters },
};

const mutations = {
  [SET_CURRENT_STEP_INVESTOR](state, payload) {
    state.step = payload;
  },
  [SET_SUBMISSION_STATUS_INVESTOR](state, payload) {
    state.status = payload;
  },
  [SET_SUBMISSION_INFO_INVESTOR](state, payload) {
    state.submission = payload;
  },
  [SET_AVAILABLE_LIST](state, payload) {
    state.availableList = payload;
  },
  [SET_MATCH_LIST](state, payload) {
    state.matchList = payload;
  },
  [SET_FILTERS](state, payload) {
    const { name, value } = payload;
    state.filters[name] = value;
  },
};

const actions = {
  createNewSubmission({ commit, dispatch, rootGetters }) {
    const user = rootGetters["user/currentUser"];
    const data = {
      status: {
        step: 1,
        view: "application",
      },
      submission: {
        interest: {},
        company: {},
        lead: {
          email: user.email,
          givenName: user.firstName,
          familyName: user.lastName,
        },
      },
    };
    return axios
      .post("/alien/submissions?entity=investor", {
        platform: "fsd",
        data,
      })
      .then((response) => {
        const submission = response.data;
        commit(SET_SUBMISSION_INFO_INVESTOR, submission);

        const status = response.data.data.status;
        commit(SET_SUBMISSION_STATUS_INVESTOR, status);
        dispatch("setCurrentStep", status.step);
      });
  },
  saveSubmission({ commit, dispatch }, { submission, status }) {
    var data = {
      submission,
      status,
    };
    const currentSubmission = state.submission;
    delete currentSubmission._id;
    return axios
      .post("/alien/submissions?entity=investor", {
        ...currentSubmission,
        platform: "fsd",
        data,
      })
      .then((response) => {
        const submission = response.data;
        commit(SET_SUBMISSION_INFO_INVESTOR, submission);

        const status = response.data.data.status;
        commit(SET_SUBMISSION_STATUS_INVESTOR, status);
        dispatch("setCurrentStep", status.step);
      });
  },
  setCurrentStep({ commit }, payload) {
    commit(SET_CURRENT_STEP_INVESTOR, payload);
    const steps = {
      1: "investor-application",
      2: "investor-review",
      3: "investor-onboarding",
      4: "investor-investment",
    };
    router.push({ name: steps[payload] });
  },
  setCurrentUserSubmission({ commit, dispatch, rootGetters }) {
    const userId = rootGetters["user/userId"];
    return axios
      .get("/alien/submissions?entity=investor")
      .then((response) => {
        const userSubmissions = response.data.items.filter(
          (item) => item.userId === userId
        );
        const userSubmission = userSubmissions[0];
        if (userSubmission) {
          const status = userSubmission.data.status;
          commit(SET_SUBMISSION_STATUS_INVESTOR, status);

          const submission = userSubmission;
          commit(SET_SUBMISSION_INFO_INVESTOR, submission);

          dispatch("setCurrentStep", status.step);
        }
        return userSubmissions;
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Ok",
          },
          { root: true }
        );
      });
  },
  setNewStatus({ dispatch, state, commit }, payload) {
    var data = {
      submission: state.submission,
      status: payload,
    };
    return axios
      .post("/alien/submissions?entity=investor", {
        platform: "fsd",
        data,
      })
      .then((response) => {
        const status = response.data.data.status;
        commit(SET_SUBMISSION_STATUS_INVESTOR, status);
        dispatch("setCurrentStep", status.step);
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Ok",
          },
          { root: true }
        );
      });
  },
  setAvailableList({ commit, state }) {
    const submissionId = state?.submission?.submissionId;
    const params = { submissionId };
    const { sector, fintype } = state.filters;
    if (sector?.length) {
      params.sector = sector.join(",");
    }
    if (fintype?.length) {
      params.fintype = fintype.join(",");
    }
    return axios
      .get("/alien/published-deals", {
        params,
      })
      .then((response) => {
        commit(SET_AVAILABLE_LIST, response.data.items);
      });
  },
  addDealMatch({ state, dispatch }, payload) {
    const investorSubmissionId = _.get(state, "submission.submissionId");
    return axios
      .post("/alien/match", {
        status: "inProgress",
        investeeSubmissionId: payload,
        investorSubmissionId,
      })
      .then(() => {
        dispatch("setAvailableList");
      });
  },
  setMatchList({ state, commit }, payload) {
    const submissionId = state?.submission?.submissionId;
    return axios
      .get("/alien/matches", {
        params: { investorSubmissionId: submissionId, status: payload },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
      .then((response) => {
        commit(SET_MATCH_LIST, response.data.items);
      });
  },
  updateMatchObject({ dispatch }, payload) {
    return axios.patch("/alien/match/" + payload._id, payload).then(() => {
      dispatch("setMatchList", "inProgress");
    });
  },
  getMatchInfo(_, payload) {
    return axios.get("/alien/match/" + payload);
  },
  getInvesteeInformation(_, payload) {
    return axios.get("/alien/submissions/" + payload, {
      params: {
        entity: "investee",
      },
    });
  },
  getAdvisorsList(_, payload) {
    return axios.get(`/alien/advisors?investeeSubmissionId=${payload}`);
  },
  updateFilters({ commit, dispatch }, payload) {
    commit(SET_FILTERS, payload);
    dispatch("setAvailableList");
  },
};

const getters = {
  currentStep: (state) => state.step,
  submission: (state) => state.submission?.data?.submission,
  isStepFinished: (state) => (num) => state.status.step > num,
  view: (state) => state.status.view,
  status: (state) => state.status,
  availableList: (state) => state.availableList,
  matchList: (state) => state.matchList,
  filters: (state) => state.filters,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
